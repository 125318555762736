const localStorageAuthTokenKey = 'nextcbstoken'
const location = window.location;


export function setBearerToken(token: string) {
    localStorage.setItem(localStorageAuthTokenKey, token)
}

export function getBearerToken() {
    return localStorage.getItem(localStorageAuthTokenKey)
}

export async function logout() {
    localStorage.removeItem(localStorageAuthTokenKey);
    location.href = `/`
}

export function goToLoginPage() {
    location.href = `/`
}