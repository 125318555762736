import React from "react";

function dayNode(index: number, value: number | null, className: string, onSetDay: (day: number) => void) {
    return <span key={Math.random().toString() + index} className={className} onClick={() => onSetDay(index)}>{value}</span>
}
function renderDaysNodes(days: number, firstDayOffset: number, selectedDay: number, onSetDay: (day: number) => void) {
    const nodes = []
    const baseClassName = 'day-block'
    for (let index = 1; index < days + 1; index++) {
        if (index === 1 && firstDayOffset > 0) {
            nodes.push(dayNode(index, null, 'first-day-offset offset-' + firstDayOffset, onSetDay))
        }
        let className = baseClassName;
        if (index === selectedDay) {
            className += ' selected'
        }
        nodes.push(dayNode(index, index, className, onSetDay))
    }
    return nodes
}
export const DatePickerBody = ({ days, firstDayOffset, selectedDay, onSetDay }: { days: number, firstDayOffset: number, selectedDay: number, onSetDay: (day: number) => void }) => {
    return <div className='date-picker-np_body'>
        <div className="days-of-week">
            <span className="day-block">S</span>
            <span className="day-block">M</span>
            <span className="day-block">T</span>
            <span className="day-block">W</span>
            <span className="day-block">T</span>
            <span className="day-block">F</span>
            <span className="day-block">S</span>
        </div>
        <div className="days-of-month">
            {renderDaysNodes(days, firstDayOffset, selectedDay, onSetDay)}
        </div>
    </div>
}
