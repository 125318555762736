import { useRoutes } from "react-router-dom";
import TopUp from "./pages/topUp";
import TopUpReport from "./pages/topUpReport";

export const RouteElement = () => {
    return useRoutes(contentRoutes);
};

export const contentRoutes = [
    {
        path: "/",
        element: <>Home</>
    },
    {
        path: "top-up",
        element: <TopUp />
    },
    {
        path: "top-up-rpt",
        element: <TopUpReport />
    },
    {
        path: "*",
        element: <>Not Found</>
    }
];

export const mainContentRoutes = [
    {
        path: "/*",
        element: <RouteElement />
    }
];
