import './Login.scss'
import axios from 'axios';
import * as React from 'react'
import { Form as AntForm, Input, Button, Select, Card, Form } from 'antd';
import { setBearerToken } from 'src/services/authService';
import { useForm } from 'antd/lib/form/Form';

const LoginForm = () => {
    const [state, setState] = React.useState<{
        loading: boolean
        posting: boolean
        userName?: string
        password?: string
    }>({ loading: false, posting: false })

    const handleSubmit = async () => {

        const res = await axios.post<any>('/login/user-login', {
            UserName: state.userName,
            Password: state.password
        })
        if (res) {
            setBearerToken(res.data)
            window.location.href = "/"
        } else {
            setState({ ...state, posting: false })
        }
    }

    const { loading, posting } = state
    const [form] = useForm()
    const FormItem = AntForm.Item;
    return <div className='page-login'>
        <Card title={`Next Sms`} style={{ minWidth: '400px' }}>
            <Form onFinish={handleSubmit} className="login-form">
                <FormItem>
                    <Input value={state.userName} onChange={val => setState({ ...state, userName: val.target.value })} required placeholder="Username" className="english" />
                </FormItem>
                <FormItem>
                    <Input value={state.password} onChange={val => setState({ ...state, password: val.target.value })} required type="password" placeholder="Password" className="english" />
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" loading={posting} className="login-form-login_button">
                        Log in
                    </Button>
                </FormItem>
            </Form>
        </Card>
    </div >
}

export default LoginForm