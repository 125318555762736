import { Route, useRoutes } from 'react-router-dom';
import { MenuProvider } from './contexts/menuContext';
import MainLayout from './layout/mainLayout';
import LoginForm from './pages/auth/pages/Login';
import { mainContentRoutes } from './routes';
import { getBearerToken } from './services/authService';

export const App = () => {
  const contentRouteElement = useRoutes(mainContentRoutes);
  
  if (getBearerToken() == null) {
    return <MenuProvider>
      <LoginForm />
    </MenuProvider>
  }

  return <MenuProvider>
    <MainLayout context={contentRouteElement} />
  </MenuProvider>
}