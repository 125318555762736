import { CalendarOutlined } from '@ant-design/icons';
import { Col, Popover, PopoverProps, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { gutterSize } from 'src/layout/constant';
import { composeNpDate, getAdDate, getDays, getNpDate, getWeekDay, parseNpDate } from 'src/services/dateService';
import { showErrorMessage } from 'src/services/messageService';
import { DatePickerBody } from './datePickerBody';
import { DatePickerFooter } from './datePickerFooter';
import { DatePickerHeader } from './datePickerHeader';
import './datePickerNp.scss';
import Cleave from 'cleave.js/react';

interface Props {
  date?: string;
  value?: Date;
  onChange?: (ad: Date | null, bs: string | null) => void;
  title?: string;
  disabled?: boolean;
}

function getInitialDate({ date, value: dateAd }: Props) {
  if (dateAd) return getNpDate(dateAd);
  if (date) return date;
  return getNpDate(new Date());
}



// start...
const Picker = (props: Props) => {
  const initialDate = getInitialDate(props);
  const { year, month, day } = parseNpDate(initialDate);

  const [dateAd, setDateAd] = useState<Date>();
  const [currDay, setCurrDay] = useState<number>(day);
  const [currMonth, setCurrMonth] = useState<number>(month);
  const [currYear, setCurrYear] = useState<number>(year);
  const [visible, setVisible] = useState<boolean>();


  const popOverRef = React.createRef<PopoverProps>();

  const { title } = props;

  useEffect(() => {
    const dateNp = getInitialDate(props);
    if (dateNp) {
      const dateAd = getAdDate(dateNp);
      props.onChange && props.onChange(dateAd as any, dateNp);
    }
  }, []);


  useEffect(() => {
    if (
      initialDate !== getInitialDate(props) ||
      dateAd !== props.value
    ) {
      const todayNp = getNpDate(new Date())

      let npDate = props.date || todayNp;

      if (props.value) {
        npDate = getNpDate(props.value) || todayNp;
      }

      const { day, month, year } = parseNpDate(npDate);

      setCurrDay(day);
      setCurrMonth(month);
      setCurrYear(year);
      setDateAd(props.value);
      setVisible(visible);
    }
  }, [getInitialDate(props), props.value]);


  const getCurrentDate = () => {
    return composeNpDate(currYear, currMonth, currDay);
  };

  const handleDayChange = (value: number) => {
    setCurrDay(value);
    setVisible(false);
    handleDateChange(currYear, currMonth, value);
  };

  const handleMonthChange = (value: number) => {
    const totalDays = getDays(currYear, value);
    let day = 1;
    if (totalDays) {
      day = currDay > totalDays ? totalDays : currDay;
    }

    setCurrMonth(value);
    setCurrDay(day);

    handleDateChange(currYear, value, day);
  };

  const handleYearChange = (value: number) => {
    const totalDays = getDays(value, currMonth);
    let day = 1;
    if (totalDays) {
      day = currDay > totalDays ? totalDays : currDay;
    }

    setCurrYear(value);
    setCurrDay(day);
    handleDateChange(value, currMonth, day);
  };

  const handleDateChange = (year: number, month: number, day: number) => {
    const npDate = composeNpDate(year, month, day);
    const adDate = getAdDate(npDate);

    props.onChange && props.onChange(adDate as any, npDate);
  };

  const getFirstDayOffset = () => {
    const firstDay = `${currYear.toString()}/${currMonth}/01`;
    return getWeekDay(firstDay);
  };
  const renderDatePicker = () => {
    return (
      <div className="date-picker-np">
        <DatePickerHeader
          month={currMonth}
          year={currYear}
          onSetMonth={month => handleMonthChange(month)}
          onSetYear={year => handleYearChange(year)}
        />
        <DatePickerBody
          days={getDays(currYear, currMonth) || 30}
          firstDayOffset={getFirstDayOffset()}
          selectedDay={currDay}
          onSetDay={day => handleDayChange(day)}
        />
        <DatePickerFooter />
      </div>
    );
  };

  return (
    <Popover
      overlayStyle={{ padding: 0 }}
      overlayClassName='date-picker-np-popover'
      trigger='click'
      ref={popOverRef}
      placement='bottom'
      content={renderDatePicker()}
      visible={visible && !props.disabled}>

      <Tooltip title={title ? title : ''} placement='bottom'>
        <Row gutter={gutterSize}>
          <Col md={24}>
            <Cleave
              placeholder={"YYYY/MM/DD"} className='ant-input'
              options={{
                blocks: [4, 2, 2],
                delimiter: '/',
                numericOnly: true
              }}
              onDoubleClick={() => setVisible(true)}
              onChange={
                (e) => {
                  var newDate = e.target.value.replace(/(\/)/g, '')

                  if (e.target.value.length == 10) {
                    if (Number(e.target.value.split('/')[1]) > 12 || Number(e.target.value.split('/')[1]) == 0) {
                      showErrorMessage(`Invalid Month Date ${Number(e.target.value.split('/')[1])}`)
                      return;
                    }

                    if (Number(e.target.value.split('/')[2]) > 32 || Number(e.target.value.split('/')[2]) == 0
                      || Number(e.target.value.split('/')[2]) > Number(getDays(Number(e.target.value.split('/')[0]), Number(e.target.value.split('/')[1])) || 0)
                    ) {
                      showErrorMessage(`Invalid Day Date ${Number(e.target.value.split('/')[2])}`)
                      return;
                    }

                    if (Number(e.target.value.split('/')[0]) < 2001) {
                      showErrorMessage(`Invalid Year Date ${Number(e.target.value.split('/')[0])}`)
                      return;
                    }

                    if (Number(newDate) >= 0) {
                      setCurrYear(Number(e.target.value.split('/')[0]));
                      setCurrMonth(Number(e.target.value.split('/')[1]));
                      setCurrDay(Number(e.target.value.split('/')[2]));

                      handleDateChange(Number(e.target.value.split('/')[0]),
                        Number(e.target.value.split('/')[1]),
                        Number(e.target.value.split('/')[2]))
                    }
                  }
                }
              }
              // style={props.style}
              readOnly={props.disabled}
              value={composeNpDate(currYear, currMonth, currDay)}
            />
          </Col>

          <Col md={1} style={{ margin: "auto", marginLeft: '-2.5rem' }}>
            <Tooltip title="Show Calender">
              <CalendarOutlined onClick={() => setVisible(true)} />
            </Tooltip>
          </Col>
        </Row>
      </Tooltip>
    </Popover>
  );
};
const DatePickerNp = Picker;
export { DatePickerNp };

