export const gutterSize = {
  sm: 16,
  md: 8,
};

export const required = {
  rules: [{ required: true, message: "Required" }],
};

export const itemLayout = {
  labelCol: {
    xs: 24,
    sm: 6,
    lg: 4,
  },
  wrapperCol: {
    xs: 24,
    sm: 18,
    lg: 8,
    xl: 6,
  },
};

export const rightItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 18,
      offset: 6,
    },
    lg: {
      span: 8,
      offset: 4,
    },
    xl: {
      span: 6,
      offset: 4,
    },
  },
};

export const rightActionLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 18,
      offset: 6,
    },
    lg: {
      span: 21,
      offset: 4,
    },
  },
};

// form layout
export const FORMLAYOUT = {
  labelCol: {
    xs: 24,
    md: 12,
    lg: 8,
  },
  wrapperCol: {
    xs: 24,
    md: 12,
    lg: 8,
  },
};

export const FORMROWGUTTER = {
  xs: 8,
  sm: 15,
  md: 18,
};

export const FROMROWCOLWIDTH = {
  xs: 24,
  sm: 12,
  md: 8,
  lg: 6,
};

// default horizantal tab icon size
export const TABICONSIZE = 18;