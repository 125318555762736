import './layout.scss'
import React, { useState } from 'react';
import { Menu, Divider, PageHeader, Tooltip, Select, Layout, Card } from 'antd';
import { useMenuContext } from 'src/contexts/menuContext';
import { AiOutlineAlignLeft, AiOutlineBank, AiOutlineFileSearch, AiOutlineLogout, AiOutlineQuestionCircle, AiTwotoneBank } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { logout } from 'src/services/authService';
const { Content, Footer, Sider } = Layout

interface Props {
    context: any
}

const MainLayout = (props: Props) => {
    const Icon = ({ type }: any) => {
        if (type == "FileSearchOutlined") {
            return <AiOutlineFileSearch />
        }

        if (type == "BankOutlined") {
            return <AiOutlineBank />
        }
        return <AiOutlineQuestionCircle />
    }

    const [state, setState] = useState<{
        activeId: number, activeChild: number, defaultMenuId: number,
        openKeys: string[],
        selectedKey: string[],
        basePath: string,
        officeName: string,
        collapsed?: boolean

        passwordModal?: boolean
        currPw?: string
        newPw?: string
        reNewPw?: string
        dateModal: boolean
        tranDate?: Date
    }>({
        activeChild: 0, activeId: 0, defaultMenuId: 0, openKeys: [],
        selectedKey: [], basePath: '', officeName: '', dateModal: false, collapsed: localStorage.menuCollapsed
    })

    const onCollapsed = (collapsed: boolean) => {
        setState({ ...state, collapsed, openKeys: [] })
        if (collapsed) localStorage.menuCollapsed = 'true'
        else localStorage.removeItem('menuCollapsed')
    }

    var { openKeys, selectedKey, officeName, collapsed } = state
    const { menus } = useMenuContext()

    const unCollapsedMenus = menus
        .sort((a, b) => Number(a.id) - Number(b.id))
        .map((c) => (
            <Menu.Item key={c.menuName} className="menu_item">
                <Link to={c.url} onClick={() => setState({ ...state, selectedKey: [c.menuName] })}>
                    {c.menuName}
                </Link>
            </Menu.Item>
        ))

    const collapsedMenus = menus
        .sort((a, b) => Number(a.id) - Number(b.id))
        .map((c) => (
            <Menu.Item key={c.menuName} className="menu_item">
                <Link to={c.url} onClick={() => setState({ ...state, selectedKey: [c.menuName] })}>
                    <Icon type={c.icon} title={c.menuName} />
                </Link>
            </Menu.Item>
        ))
    return <Layout style={{ minHeight: '100vh' }}>
        <Sider width={300} collapsed={state.collapsed} className="main-menu"
            breakpoint="sm">
            <div className="sider-menu-logo">
                <Link to={'/'}>
                    {collapsed ? <img src="/home/logo" /> :
                        <React.Fragment>
                            <img src="/home/logo" />
                            <h1>Next Sms</h1>
                        </React.Fragment>
                    }
                </Link>
            </div>

            <Menu openKeys={openKeys} mode="inline" selectedKeys={selectedKey} theme="dark">
                {!state.collapsed ? unCollapsedMenus : collapsedMenus}
            </Menu>
        </Sider>
        <Layout style={collapsed ? { marginLeft: '80px' } : { marginLeft: '300px' }}>
            <PageHeader title={<a onClick={() => onCollapsed(!collapsed)}>
                {collapsed ? <AiOutlineAlignLeft /> : <AiOutlineAlignLeft />}
            </a>}
                subTitle={<React.Fragment>{officeName}
                    <Divider type="vertical" />
                    {/* <Tooltip title={localize("LoggedInBranch")}>{userMeta.BranchName}</Tooltip>
                    <Divider type="vertical" />
                    <Tooltip title={localize("LoggedInUser")}>{userMeta.UserName}</Tooltip>
                    <Divider type="vertical" />
                    <Tooltip title={localize("TransactionDate")}>{userMeta.TodayNp}</Tooltip> */}
                </React.Fragment>}
                extra={<React.Fragment>
                    <Divider type="vertical" />
                    <Tooltip title="Change Password">
                        {/* <a onClick={() => setState({ ...state, passwordModal: true })}><Icon component={PadlockIcon} /></a> */}
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip title="Log Out">
                        <a onClick={logout}><AiOutlineLogout /></a>
                    </Tooltip>
                </React.Fragment>}
            />

            <Content className="main-content">
                <Card style={{ width: '100%', marginTop: '10px' }}>
                    {props.context}
                </Card>
            </Content>
        </Layout>
    </Layout>
}

export default MainLayout
