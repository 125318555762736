import React from "react";
import { getAllYears } from "src/services/dateService";

const months = ['Baisakh', 'Jestha', 'Asadh', 'Shrawan', 'Bhadra', 'Aswin', 'Kartik', 'Manshir', 'Paush', 'Magh', 'Falgun', 'Chaitra']
interface Props {
    year: number;
    month: number;
    onSetMonth(month: number): void;
    onSetYear(year: number): void;
}
function prevMonth({ month, year, onSetMonth, onSetYear }: Props) {
    if (month == 1) {
        onSetMonth(12);
        onSetYear(--year);
    } else {
        onSetMonth(--month)
    }
}
function nextMonth({ month, year, onSetMonth, onSetYear }: Props) {
    if (month == 12) {
        onSetMonth(1);
        onSetYear(++year)
    } else {
        onSetMonth(++month)
    }
}
export const DatePickerHeader = (props: Props) => {
    let { year, month, onSetMonth, onSetYear } = props;
    return <div className='date-picker-np_header'>
        <span className='date-picker-np_header_nav' onClick={() => onSetYear(--year)}>{'<<'}</span>
        <span className='date-picker-np_header_month'>
            <span className='date-picker-np_header_nav' onClick={() => prevMonth(props)}>{'<'}</span>
            <select onChange={(e) => onSetMonth(parseInt(e.target.value))} value={month}>
                {months.map((month, i) => <option key={i} value={i + 1}>{month}</option>)}
            </select>
            <select onChange={(e) => onSetYear(parseInt(e.target.value))} value={year}>
                {getAllYears().map((year, i) => <option key={i} value={year.toString()}>{year}</option>)}
            </select>
            <span className='date-picker-np_header_nav' onClick={() => nextMonth(props)}>{'>'}</span>
        </span>
        <span className='date-picker-np_header_nav' onClick={() => onSetYear(++year)}>{'>>'}</span>
    </div>
}