import { useState } from "react"
import { createContext, useContext } from "react"

type Menu = {
    id: number
    menuName: string
    icon?: string
    url: string
}

interface MenuContextProps {
    menus: Menu[]
}

export const MenuContext = createContext<MenuContextProps>({
    menus: []
})

export const useMenuContext = () => useContext(MenuContext)

export const MenuProvider: React.FC = ({ children }) => {
    const [menu, setMenus] = useState<MenuContextProps>({
        menus: [{
            id: 1,
            menuName: 'Top Up',
            url: '/top-up',
            icon: 'BankOutlined'
        },
        {
            id: 2,
            menuName: 'Top up Report',
            url: '/top-up-rpt',
            icon: 'FileSearchOutlined'
        }]
    })

    return <MenuContext.Provider value={menu}>
        {children}
    </MenuContext.Provider>
} 