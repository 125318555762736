import { Button, Col, Form, Row, Select, Table, Typography } from "antd"
import { useForm } from "antd/lib/form/Form"
import FormItem from "antd/lib/form/FormItem"
import { ColumnType } from "antd/lib/table"
import { useEffect, useState } from "react"
import { AiFillFileExcel, AiOutlineSave } from "react-icons/ai"
import { DatePickerNp } from "src/components/datePicker/datePickerNp"
import { gutterSize } from "src/layout/constant"
import { get, post } from "src/services/ajaxService"
import { getNpDate } from "src/services/dateService"
import { showSuccessMessage } from "src/services/messageService"

interface customer {
    id: number
    customerName: string
}

interface topUpRptModel {
    customerId?: number
    tranDate?: Date
    credit: number
    statementReference: string
}

const TopUp = () => {
    const [customers, setCustomers] = useState<customer[]>([]);
    const [data, setData] = useState<topUpRptModel[]>([]);
    const [customerId, setCustomerId] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const init = async () => {
        const res = await get<customer[]>('/Client/all')
        if (res) setCustomers(res.data)
    }

    const [form] = useForm()
    useEffect(() => { init() }, [])

    const handlePost = async () => {
        var values = await form.validateFields()
        setLoading(true)
        const res = await post<topUpRptModel[]>('/tran/top-up-report', {
            CustomerId: values.customerId || 0,
            FromDate: values.fromDate || new Date(),
            ToDate: values.toDate || new Date()
        })
        if (res) {
            setData(res.data);
            setLoading(false)
        }
    }

    const handleDownload = async () => {
        setLoading(true)
        var values = await form.validateFields()
        const res = await post<string>('/tran/top-up-report-xls', {
            CustomerId: values.customerId || 0,
            FromDate: values.fromDate || new Date(),
            ToDate: values.toDate || new Date()
        })
        if (res) {
            showSuccessMessage()
            setLoading(false)
            window.open(res.data, '_blank')
        }
    }

    const cols: ColumnType<topUpRptModel>[] = [{
        title: 'Customer', dataIndex: 'customerId', render: (val) => !val ? "Total" : customers.filter(x => x.id == val)[0].customerName
    },
    {
        title: 'TranDate', dataIndex: 'tranDate', render: (val) => val ? getNpDate(val) : null
    },
    {
        title: 'Balance', dataIndex: 'credit'
    }
    ]

    return <Form form={form} layout="vertical">
        <Typography.Title level={4}>Top Up Report</Typography.Title>
        <Row gutter={gutterSize}>
            <Col md={6}>
                <FormItem label="Customer Name" name="customerId">
                    <Select style={{ width: '100%' }} value={customerId} showSearch allowClear optionFilterProp="children" onChange={val => setCustomerId(val)}>
                        {customers.map(x => <Select.Option key={x.id} value={x.id}>{x.customerName}</Select.Option>)}
                    </Select>
                </FormItem>
            </Col>
            <Col md={4}>
                <FormItem label="From Date" name="fromDate">
                    <DatePickerNp />
                </FormItem>
            </Col>
            <Col md={4}>
                <FormItem label="To Date" name="toDate">
                    <DatePickerNp />
                </FormItem>
            </Col>
            <Col md={6}>
                <FormItem label=".">
                    <Button type="primary" icon={<AiOutlineSave />} loading={loading} onClick={handlePost}>Show</Button>
                    {data.length > 0 ? <Button icon={<AiFillFileExcel />} loading={loading} onClick={handleDownload}>Excel</Button> : null}
                </FormItem>
            </Col>
        </Row>
        <br />
        {data.length > 0 ? <Table size="small" dataSource={[...data, {
            credit: data.reduce((a, b) => a + b.credit, 0),
            statementReference: ''
        }]} pagination={false} columns={cols} /> : null}
    </Form>
}
export default TopUp