import { Button, Card, Col, Form, InputNumber, Row, Select, Typography } from "antd"
import { useForm } from "antd/lib/form/Form"
import FormItem from "antd/lib/form/FormItem"
import TextArea from "antd/lib/input/TextArea"
import { useEffect, useState } from "react"
import { AiOutlineSave } from "react-icons/ai"
import { gutterSize } from "src/layout/constant"
import { get, post } from "src/services/ajaxService"
import { showSuccessMessage } from "src/services/messageService"

interface customer {
    id: number
    customerName: string
}

const TopUp = () => {
    const [customers, setCustomers] = useState<customer[]>([]);
    const [customerId, setCustomerId] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const init = async () => {
        const res = await get<customer[]>('/Client/all')
        if (res) setCustomers(res.data)
    }

    const [form] = useForm()
    useEffect(() => { init() }, [])

    const handlePost = async () => {
        var values = await form.validateFields()
        const res = await post<boolean>('/tran', {
            CustomerId: values.customerId,
            Balance: values.balance,
            Desc: values.description
        })
        setLoading(true)
        if (res) {
            showSuccessMessage();
            form.resetFields();
        }
        setLoading(false)
    }
    return <Form form={form} layout="vertical">
        <Typography.Title level={4}>Top Up</Typography.Title>
        <Row gutter={gutterSize}>
            <Col md={6}>
                <FormItem label="Customer Name" name="customerId">
                    <Select style={{ width: '100%' }} showSearch optionFilterProp="children" onChange={val => setCustomerId(val)}>
                        {customers.map(x => <Select.Option key={x.id} value={x.id}>{x.customerName}</Select.Option>)}
                    </Select>
                </FormItem>
            </Col>
            <Col md={4}>
                <FormItem label="Balance" name="balance">
                    <InputNumber min={1} style={{ width: "100%" }} />
                </FormItem>
            </Col>
        </Row>
        <Row gutter={gutterSize}>
            <Col md={10}>
                <FormItem label="Description" name="description">
                    <TextArea style={{ width: '100%', height: '100%' }} />
                </FormItem>
            </Col>
        </Row>
        <Row gutter={gutterSize}>
            <Col md={6}>
                <Button type="primary" icon={<AiOutlineSave />} loading={loading} onClick={handlePost}>Save</Button>
            </Col>
        </Row>
    </Form>
}
export default TopUp